import './services.css';
import Navbar from '../navbar';
import Footer from '../footer';




function Services() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>Services</h1>
        </div>
        <div className='services'>
            <div className='services-header'>
                <h1>Restore Mobile PT provides both physical therapy services and wellness visits</h1>
            </div>
            <br />
            <div><h1>Physical Therapy Services for following conditions:</h1>
            </div>
            <div>
            <li id='list'>Orthopedic Conditions: fractures, sprains, strains, and post-surgical rehabilitation
            </li><li id='list'>Musculoskeletal Disorders: back pain, neck pain, arthritis, and joint dysfunction
            </li><li id='list'>Sports Injuries: ligament tears, muscle strains, and overuse injuries
            </li><li id='list'>Neurological Conditions: stroke and Parkinson’s disease*
            </li><li id='list'>Postural Dysfunctions
            </li><li id='list'>Balance and Gait Disorders
            </li><li id='list'>Chronic Pain
            </li>
            </div>
            <br />
            <h3>*Dr. Tom Kim is certified in LSVT-BIG®, which is a specialized and highly effective treatment approach designed to address movement and mobility issues in individuals with Parkinson's disease and other neurological conditions. This evidence-based therapy program has gained recognition for its remarkable impact on improving movement amplitude, enhancing functional abilities, and ultimately enhancing the quality of life for those living with these conditions.</h3>
            <br />
            <h1>Wellness Visits:</h1>
            <h3>Wellness physical therapy is provided to help prevent injuries and health problems before they occur. A wellness visit may include exercises, lifestyle recommendations, and education aimed at improving strength, flexibility, balance, and overall physical function. </h3>
            <br />
            <div className='services-equipment'>
                <h1>Equipment We May Bring:</h1>
                <h3>Portable Treatment Table/Mat
                </h3><h3>Massage Creams and Oils
                </h3><h3>Electrical Stimulation Device
                </h3><h3>Weights (ankle, dumbbells, kettlebells)
                </h3><h3>Exercise Bands
                </h3><h3>Foam Roll
                </h3><h3>Therapeutic Tape
                </h3><h3>Theragun
                </h3><h3>Cupping Therapy
                </h3><h3>Balance Pad</h3>
            </div>
        </div>
        <Footer /></>
    )
}


export default Services;