import Navbar from '../navbar';
import './about.css';
import Footer from '../footer';
import Tom from '../images/tomkim.jpg';


function about() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>About</h1>
        </div>
        <div className='about'>
            <div className='about-picture'>
                <h1>Dr. Tom Kim PT, DPT</h1>
                <img id='tom' src={Tom} ></img>
            </div>
            <div className='about-paragraph'>
                <h3>Dr. Tom Kim has been a Physical Therapist since 2008. His deep-seated passion for helping others led him to pursue a career in physical therapy, where he could combine his interest in the human body and exercise to restore and recover one’s health.</h3>
                <br />
                <h3>After earning his Doctorate degree in Physical Therapy from Andrews University, Dr. Tom Kim gained valuable experience in diverse healthcare settings, including outpatient physical therapy and home health care. He also had the pleasure and joy of providing therapy in the mission fields and local health clinics to help those in need. </h3>
                <br />
                <h3>Residing in Calhoun, Georgia, Dr. Tom Kim is married, has three precious daughters, two dogs and one cat. He enjoys spending time with family and friends, traveling, gardening, weight lifting and playing sports. </h3>
            </div>
        </div>
        <Footer /></>
    )
}


export default about;