import './App.css';
import PT from './images/front-page.png';
import Footer from './footer';
import { Link } from 'react-router-dom';
import Navbar from './navbar';


function App() {
  return (
    <>
    <div className="App">
      <Navbar />
      {/* <div className='space'></div> */}
      
      <div className='body'>
      
        <div className='headline'>
          
        <div className='main-headline'>
          <h1 id='headline'>Welcome to Restore Mobile PT where physical therapy is delivered to you!</h1>
        </div>
        <br />
        <br />
        <br />
        <div className='paragraph2'>
          <div className='paragraph3'>
          <h3 id='paragraph3'>We are a mobile outpatient physical therapy practice, servicing Northwest Georgia, providing one-on-one physical therapy at the convenience of your home, work or gym. </h3>
            <br />
            <h3 id='paragraph3'>Restore Mobile PT offers customized treatment plans to meet your unique needs. We focus on restoring functionality, reducing pain, and enhancing your overall quality of life. Through skilled manual techniques, therapeutic exercises, modalities, and patient education, we aim to help you restore and achieve optimum health.</h3>
          </div>
        </div>
          
        </div>
        
      </div>
      {/* <div className='profile-photo'>
          <img id='pt' src={PT}></img>
        </div> */}
    </div>
    <div>
      <Footer />
    </div>
    </>
  );
}

export default App;
