import './location.css'
import Navbar from '../navbar';
import Footer from '../footer';
import Map from '../images/pt_map.png';

function Location() {
    return (
        <><Navbar />
        <div className='about-header'>
            <h1 id='about-header'>Location</h1>
        </div>
        <div className='location'>
            <div className='location-paragraph'>
                <h1>Servicing Gordon and Whitfield County and surrounding cities in Northwest Georgia.</h1>
                <br />
                <h3>Restore Mobile PT comes to you at the comfort and familiarity of your own home, work or gym. Its physical therapy delivered to you!
                </h3>
            </div>
            
        </div>
        <div>
                <img id='map' src={Map} alt="" />
            </div>
        
        <Footer /></>
    )
}


export default Location;