import { Route, Routes } from 'react-router-dom';
import App from './App';
import About from './pages/about';
import Services from './pages/services';
import Location from './pages/location';
import Contact from './pages/contact';
import FAQ from './pages/faq';
import Contact_Reply from './pages/contact_reply';





function Nav () {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/location" element={<Location />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path='/contact_reply' element={<Contact_Reply/>} />
        </Routes>
    );
}

export default Nav; 