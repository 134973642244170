import './navbar.css'
import { Link } from 'react-router-dom';
import About from './pages/about';
import Services from './pages/services';
import Location from './pages/location';
import Contact from './pages/contact';
import FAQ from './pages/faq';
import Icon from './images/Restore Mobile PT(2).png';
import MenuButton from './MenuButton';

function Navbar() {
    return (
      <div className='navbar'>
        <div className='logo'>
          <div>
            <img id='icon' src={Icon} />
          </div>
          <div>
            <Link to='/'><h1 id='logo'>Restore Mobile PT</h1></Link>
          </div>
          
        </div>
        <div className='pages'>
          <Link to='/about' element={<About/>}><h3 id='pages'>About</h3></Link>
          <Link to='/services' element={<Services/>}><h3 id='pages'>Services</h3></Link>
          <Link to='/location' element={<Location/>}><h3 id='pages'>Location</h3></Link>
          <Link to='/contact' element={<Contact/>}><h3 id='pages'>Contact</h3></Link>
          <Link to='/faq' element={<FAQ/>}><h3 id='pages'>FAQ</h3></Link>
          
        </div>
        <MenuButton />
      </div>
    );
}



export default Navbar;