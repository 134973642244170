import './footer.css'
import { AiFillFacebook } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
// import { Link } from 


function Footer() {

    return (
        <div className='footer'>
            <div className='footer-logo'>
                <h1 id='footer-logo'>Restore Mobile PT</h1>
            </div>
            <br />
            <div className='footer-contact'>
                <h1 id='footer-contact'>Phone: 706-383-9734</h1>
                <h1 id='footer-contact'>Hours: M - F 9 to 5 PM</h1>
            </div>
            <div className='footer-icons'>
                <div><a id='footer-icons' href='https://www.facebook.com/profile.php?id=61551537770188' target='_blank'> <AiFillFacebook id='footer-icons'/> </a></div>
                <div><a id='footer-icons' href='https://www.google.com/maps/place/Restore+Mobile+PT/@34.630347,-84.8747319,10z/data=!3m1!4b1!4m6!3m5!1s0xa52c645300aac753:0x717b7ed298eb270d!8m2!3d34.630347!4d-84.874732!16s%2Fg%2F11vf03q_qr?hl=en-US&entry=ttu' target='_blank'> <AiFillGoogleCircle id='footer-icons'/> </a></div>
                <div><AiFillInstagram id='footer-icons'/></div>
            </div>
        </div>
    )
}

export default Footer;